import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  seo?: {
    title?: string;
    description?: string;
    image?: string;
    keywords?: string;
    imageWidth?: string;
    imageHeigh?: string;
    iosUrl?: string;
    appStoreId?: string;
    iosAppName?: string;
    androidUrl?: string;
    androidPackage?: string;
    androidAppName?: string;
    facebookAppId?: string;
    url?: string;
    favicon?: string;
  };
};

export const SeoHelmet = ({ seo = {} }: Props) => {
  const {
    title,
    description,
    image,
    imageWidth,
    imageHeigh,
    keywords,
    iosUrl,
    appStoreId,
    iosAppName,
    androidUrl,
    androidPackage,
    androidAppName,
    facebookAppId,
    url,
    favicon = getConfigByKey('site_logo'),
  } = seo ?? {};
  return (
    <>
      {!!title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      )}
      {!!description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )}
      {!!image && (
        <Helmet>
          <meta property="image" content={image} />
          <meta property="og:image" content={image} />
          <meta name="twitter:image" content={image} />
          <meta property="og:image:secure_url" content={image} />
        </Helmet>
      )}
      {!!url && (
        <Helmet>
          <meta property="url" content={url} />
          <meta property="og:url" content={url} />
        </Helmet>
      )}
      {!!keywords && (
        <Helmet>
          <meta name="keywords" content={keywords} />
          <meta name="og:keywords" content={keywords} />
        </Helmet>
      )}
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="type" content="video.movie" />
        <meta property="og:type" content="video.movie" />

        {!!imageWidth && <meta property="og:image:width" content={imageWidth} />}

        {!!imageHeigh && <meta property="og:image:height" content={imageHeigh} />}
        <meta property="al:web:should_fallback" content="false" />

        {!!iosUrl && <meta property="al:ios:url" content={iosUrl} />}

        {!!appStoreId && <meta property="al:ios:app_store_id" content={appStoreId} />}

        {!!iosAppName && <meta property="al:ios:app_name" content={iosAppName} />}
        {!!androidUrl && <meta property="al:android:url" content={androidUrl} />}

        {!!androidPackage && <meta property="al:android:package" content={androidPackage} />}

        {!!androidAppName && <meta property="al:android:app_name" content={androidAppName} />}

        {!!facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
        {!!favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>
    </>
  );
};
