import { styled } from 'twin.macro';
export const DivStyled = styled.div`
  padding: 0 15px;
  background: ${props => `${props.props.isCategoryPage ? `#333333` : `rgb(51,51,51,0.45)`}`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: ${props => `${props.props.isCategoryPage ? `relative` : `absolute`}`};
  font-size: 1rem;
  width: 100%;
  flex-wrap: wrap;
  .v-subCategory {
    &__tenant {
      cursor: pointer;
      margin: 0;
      font-size: 1.25rem;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgb(37, 116, 212);
      display: block;
      padding: 0.9rem 1.25rem 0.9rem 0;
      text-decoration: none;
      i {
        padding-left: 1rem;
      }
    }
    &--wrapSelect {
      display: none;
    }
    &--link {
      color: ${props => `${props.props.isCategoryPage ? `#aeafb0` : `#fff`}`};
      font-size: 1rem;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      padding: 0.9rem 1.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover,
      &.active {
        text-decoration: none;
        color: ${props => `${props.props.isCategoryPage ? `#aeafb0` : `#fff`}`};
        background: ${props => `${props.props.isCategoryPage ? `#000000` : `#242424`}`};
      }
    }
    &__dropdown {
      display: block;
      cursor: pointer;
      color: #fff;
    }
    &--close {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      span {
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
        width: 10%;
        display: flex;
        justify-content: center;
      }
    }
    &__selector {
      position: fixed;
      top: 70px;
      left: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 1.2rem 1.6rem;
      background-image: linear-gradient(180deg, rgba(30, 35, 42, 0.9), rgba(17, 18, 18, 0.9));
      overflow-y: scroll;
      max-height: calc(100vh - 70px);
      hr {
        width: 100%;
      }
      &--subcategory {
        padding: 1rem 0;
        color: #9b9b9b;
        font-size: 1.25rem;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        :hover {
          text-decoration: none;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    &--selected {
      color: #fff;
      cursor: pointer;
    }
  }
  @media (min-width: 768px) {
    padding: 0 45px;
  }

  @media (min-width: 992px) {
    .v-subCategory {
      &--wrapSelect {
        display: flex;
      }
      &__tenant,
      &__dropdown {
        display: none;
      }
    }
  }
`;

export const RowStyled = styled.div`
  &.v-home {
  }
  .v-home {
    &--container {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      padding-bottom: 20px;
    }
    &__wrap {
      display: flex;
      padding-left: 15px;
      &--only {
        display: flex;
        flex-direction: column;
      }
    }
    &__right-site {
      padding-right: 0;
      display: none;
    }
    &__left-site {
      padding: 0;
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-home {
      &__wrap {
        padding-inline: 45px;
      }
    }
  }

  @media (min-width: 992px) {
    .v-home {
      &__wrap {
        padding: 0 45px;
        &--only {
          /* padding-right: 0; */
        }
      }
      &__right-site {
        display: block;
        margin-top: 0.5rem;
        &.col-lg-4 {
          width: 20em;
        }
      }
      &__left-site {
        &.col-lg-8 {
          width: calc(100% - 20em);
        }
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;
